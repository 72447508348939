
const { getCurrentCountry, isValidURL, getCookie } = require('./utils')

exports.OneTrustScript = (
  options
) => {
  try {
    const country = getCurrentCountry()
    const isProd = window.sessionStorage.getItem('environmentName');
    const otKey = options.dataAttr.domainScript[country.toUpperCase()]

    if(
      !!options &&
      !!options.url &&
      !!options.dataAttr &&
      !!options.dataAttr.domainScript &&
      !!otKey
    ) {
      let otScriptDomain = isProd === 'PROD' ? otKey : otKey + "-test"
      let countriesWithOneTrustBanner = !!otScriptDomain
      const url = options.url,
        documentLanguage = options.dataAttr.documentLanguage || 'true',
        inHead = !!options.head,
        beforeLoad = !!options.beforeLoad
      const otTest = getCookie("otTest");
      
      if (otTest !== null && otTest === "true") {
        countriesWithOneTrustBanner = true;
      }

      if(
        !isValidURL(url) && !countriesWithOneTrustBanner
      ) {
        console.log(`Please verify the one truest url: ${options.url} or check the country ${country}`)
        return
      }

      /* Updating browser language in format language-Country example us/en will be en-US */
      /* collection contains special languages according to world language standards */
      (function () {
        if (countriesWithOneTrustBanner) {
          let url = window.location.href || '';
          url = url.indexOf("cookie-information") !== -1 ? document.referrer : url;
          let specialLangs = {
            'es-US': 'es-CO'
          };
          if (url) {
            let splitUrl = url.split('/');
            let country = splitUrl[4];
            let language = splitUrl[5];
            if (country && language) {
              let browserLang = language + "-" + country.toUpperCase();
              /* replacing with specialLangs */
              document.documentElement.lang = specialLangs[browserLang] ? specialLangs[browserLang] : browserLang;
            }
          }
        }
      })();

      (function (window, t) {
        window.addEventListener('load', function() {
          window.OptanonWrapper = function() { 
              let eOT = new Event('OneTrustGroupsUpdated');
              document.dispatchEvent(eOT);
              let dateN = new Date();
              dateN.setDate(dateN.getDate() + 365);
              let expires = `expires=${dateN.toUTCString()}`;
              document.cookie = `userCookieOptIn=${window.OptanonActiveGroups}';domain=.westernunion.com;path=/;expires=${expires}`;
              window.Optanon.OnConsentChanged(function (e) {
                //fix for PBL-6056, setting OneTrust specific cookies without path, to fix issues on global pages which doesn't have context path for countries
                //setting cookie for one year just like one trust doing
                let date = new Date();
                date.setTime(date.getTime()+(365*24*60*60*1000)); //one year expiry
                let expires = "; expires="+date.toUTCString();

                let OptanonAlertBoxClosed = window.getCookie('OptanonAlertBoxClosed');
                document.cookie = "OptanonAlertBoxClosed=" + OptanonAlertBoxClosed + expires + ";path=/";

                let OptanonConsent = window.getCookie('OptanonConsent');
                document.cookie = "OptanonConsent=" + OptanonConsent + expires + ";path=/";
                
                if (country.toLowerCase() !== 'us') {
                  window.location.reload();
                }
              });
          }
        })
      })(window);

      return { 
        src: url,
        beforeLoad: !!options.beforeLoad,
        defer: !!options.defer,
        async: !!options.async,
        onDCL: !!options.onDCL,
        'inHead': inHead,
        'beforeLoad': beforeLoad,
        'data': {
          'document-language': documentLanguage || 'true',
          'domain-script': otScriptDomain 
        }
      }
    } else {
      console.log(`Please verify the One Trust url: ${options.url} or check the one trust is enabled for country ${country}`)
    }
  } catch (
    e
  ) {
    console.log(e)
    console.log(`There is an error. One Trust cannot be loaded.`)
  }
}

