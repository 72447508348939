
const { isValidURL, getEnv, loadScript } = require('./utils')
const { withAssetPrefix } = require("gatsby")

const getKey = (apiKeys) => {
  let amplitudeInitValue = ""
  let env = getEnv()
  if(env.indexOf("localhost") !== -1) {
      amplitudeInitValue =  apiKeys["localhost"]
  } else if (env.indexOf("dev41") !== -1 || env.indexOf("dev91") !== -1  || env.indexOf("dev11") !== -1) {
      amplitudeInitValue = apiKeys["dev41"]
  } else if (env.indexOf("qa51") !== -1 || env.indexOf("qa11") !== -1 ) {
      amplitudeInitValue =  apiKeys["qa51"]
  } else if (env.indexOf("uatci2") !== -1 || env.indexOf("uatrs2") !== -1) {
      amplitudeInitValue =  apiKeys["uatci2"]
  } else if (env.indexOf("uatci4") !== -1) {
      amplitudeInitValue =  apiKeys["uatci4"]
  } else if (env.indexOf("uatrs4") !== -1) {
      amplitudeInitValue = apiKeys["uatrs4"]
  } else if (env.indexOf("wudispatcherci") !== -1 || env.indexOf("wudispatcherrs") !== -1 || env.indexOf("www.westernunion.com") !== -1 || env.indexOf("partners.westernunion.com") !== -1 || env.indexOf("www.westernunion.ru") !== -1) {
      amplitudeInitValue =  apiKeys["prod"]
  } else {
    amplitudeInitValue =  apiKeys["qa11"]
  }
  return amplitudeInitValue;
}

exports.AmplitudeScript = (
  options
) => {
  try {
    if(
      !!options &&
      !!options.url
    ) {
      const url = options.url

      const apiKey = getKey(options.apiKeys)

      if(
        !isValidURL(url) ||
        !apiKey
      ) {
        console.log(`Please verify the amplitude url: ${options.url} or check api key: ${apiKey}`)
        return
      }

      (function (e, t) {
        e.addEventListener('load', function() {
          e.amplitudeInit = function () {
            if (e.amplitude.runQueuedFunctions) {
              e.amplitude.runQueuedFunctions();
              e.amplitude.getInstance().init(
                  apiKey, 
                  null, 
                  {
                    includeUtm: true, 
                    includeReferrer: true, 
                    saveParamsReferrerOncePerSession: 
                    false
                  }
                );
            } else {
              console.log("[Amplitude] Error: could not load SDK");
            }
          }

          var n = e.amplitude || { _q: [], _iq: {} };
          function s(e, t) {
            e.prototype[t] = function () {
              this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));return this;
            };
          }
          var o = function o() {
            this._q = [];return this;
          };var a = ["add", "append", "clearAll", "prepend", "set", "setOnce", "unset"];for (var u = 0; u < a.length; u++) {
            s(o, a[u]);
          }n.Identify = o;var c = function c() {
            this._q = [];return this;
          };var l = ["setProductId", "setQuantity", "setPrice", "setRevenueType", "setEventProperties"];for (var p = 0; p < l.length; p++) {
            s(c, l[p]);
          }n.Revenue = c;var d = ["init", "logEvent", "logRevenue", "setUserId", "setUserProperties", "setOptOut", "setVersionName", "setDomain", "setDeviceId", "setGlobalUserProperties", "identify", "clearUserProperties", "setGroup", "logRevenueV2", "regenerateDeviceId", "logEventWithTimestamp", "logEventWithGroups", "setSessionId", "resetSessionId"];function v(e) {
            function t(t) {
              e[t] = function () {
                e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              };
            }
            for (var n = 0; n < d.length; n++) {
              t(d[n]);
            }
          }v(n);n.getInstance = function (e) {
            e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();if (!n._iq.hasOwnProperty(e)) {
              n._iq[e] = { _q: [] };v(n._iq[e]);
            }return n._iq[e];
          };e.amplitude = n;
        });
      })(window, document);
      // Initialize the library with your `apiKey`.
      // amplitude.getInstance().init(apiKey);

      return { 
        src: url,
        defer: !!options.defer,
        beforeLoad: !!options.beforeLoad,
        async: !!options.async,
        onDCL: !!options.onDCL,
        'data': {
          'api-key': apiKey
        },
        'onload': function () {
          var apiKey =this.getAttribute("data-api-key")
          if (window.amplitude.runQueuedFunctions) {
            window.amplitude.runQueuedFunctions();
            window.amplitude.getInstance().init(
                apiKey, 
                null, 
                {
                  includeUtm: true, 
                  includeReferrer: true, 
                  saveParamsReferrerOncePerSession: 
                  false
                }
              );
              const { WUDistDataAccess } = options.dependencies
              if(WUDistDataAccess) {
                loadScript({
                  src: withAssetPrefix(WUDistDataAccess.url),
                  defer: !!WUDistDataAccess.defer,
                  async: !!WUDistDataAccess.async,
                })
              }
          } else {
            console.log("[Amplitude] Error: could not load SDK");
          }
        }
      }
    } else {
      console.log(`Please verify the amplitude url: ${options.url}`)
    }
  } catch (
    e
  ) {
    console.log(`There is an error. Amplitude cannot be loaded.`)
  }
}

