const { isValidURL, isHomePage, getEnv, getAssetVersion } = require("./utils")
const { withAssetPrefix } = require("gatsby")

exports.WuDataEventCaptureScript = options => {
  try {
    if (!!options) {
      options.url = withAssetPrefix("/scripts/vendors/WUAnalyticEventCapture.js") 
      console.log(options.url)
      if (options.url) {
        return {
          src: options.url,
          beforeLoad: !!options.beforeLoad,
          defer: !!options.defer,
          async: !!options.async,
          onDCL: !!options.onDCL,
        }
      } else {
        return
      }
    } else {
      console.log(`Please verify the Wu Data Event Capture url: ${options.url}`)
    }
  } catch (e) {
    console.log(`There is an error. Wu Data Event Capture cannot be loaded.`)
  }
}
