
const { loadScripts, isCategoryEnabled, isGDPRCountry, getCookie } = require('./src/utils')

const { OneTrustScript } = require('./src/onetrust')
const { AmplitudeScript } = require('./src/amplitude')
const { AdobeLaunchScript } = require('./src/adobe-launch')
const { AudioEyeScript } = require('./src/audio-eye')
const { BranchioScript } = require('./src/branchio')
const { Md5Script } = require('./src/md5')
const {WuDataEventCaptureScript } = require('./src/wuDataEventCapture')

exports.onClientEntry = (options, pluginOptions) => {
  console.log(`Plugins loaded: ${Object.keys(pluginOptions.analytics).join(' | ')}`)

  //alScripts - afterLoad, blScripts - beforeLoad, dclScripts - domContentLoad
  let allScripts = [],
    alScripts = [],
    blScripts = [],
    dclScripts = []
  let {
    onetrust,
    amplitude,
    adobeLaunch,
    audioEye,
    branchIo,
    md5,
    WuDataEventCapture
  } = pluginOptions.analytics;
  window.switches = {}
  const gdprCountryList = onetrust.gdprCountries
  const isGDPR = isGDPRCountry(gdprCountryList)
  const userConsent = getCookie("userCookieOptIn")

  !!onetrust && allScripts.push(OneTrustScript(onetrust))
  !!md5 && isCategoryEnabled(md5['otCategory'],isGDPR, userConsent) && allScripts.push(Md5Script(md5))
  !!WuDataEventCapture && isCategoryEnabled(WuDataEventCapture['otCategory'],isGDPR, userConsent) && allScripts.push(WuDataEventCaptureScript(WuDataEventCapture))  
  !!amplitude && isCategoryEnabled(amplitude['otCategory'],isGDPR, userConsent) && allScripts.push(AmplitudeScript(amplitude))
  !!adobeLaunch && isCategoryEnabled(adobeLaunch['otCategory'],isGDPR, userConsent) &&  allScripts.push(AdobeLaunchScript(adobeLaunch))
  !!audioEye && isCategoryEnabled(audioEye['otCategory'],isGDPR, userConsent) && allScripts.push(AudioEyeScript(audioEye))
  !!branchIo && isCategoryEnabled(branchIo['otCategory'],isGDPR, userConsent) && allScripts.push(BranchioScript(branchIo))

  for(let index in allScripts) {
    const script = allScripts[index]

    if(script) {
      if(script.beforeLoad) blScripts.push(script)
      else if(script.onDCL) dclScripts.push(script)
      else alScripts.push(script)
    }
  }

  loadScripts(blScripts)
  if (/complete|interactive|loaded/.test(document.readyState)) {
    loadScripts(dclScripts)
  } else {
    document.addEventListener("DOMContentLoaded", function(){
      loadScripts(dclScripts)
    })
  }
  window.addEventListener('load', function() {
    loadScripts(alScripts)
  })
}
