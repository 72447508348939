import axios from 'axios'
import appendScript from '../services/append-script';
export const isBrowser = () => typeof window !== `undefined`

export const fetchHTML = (url) => {
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
};

export const getEnv = () => {
  let env;
  if (isBrowser()) {
    const { protocol, hostname, port } = window.location;
    if (hostname.indexOf('westernunion.ru') !== -1) { //Checking for Russia
      env = window.location.origin;
    }
    else if (hostname.indexOf('www.westernunion.com') === -1) {
      env = `https://wudispatcher-uatrs2.westernunion.com`;
    } else {
      if (!window.location.origin) { // Internet Explorer does not have access to window.location.origin
        window.location.origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
      }
      env = window.location.origin;
    }
    return env
  }
  return false;
}

export const getSwitch = key => {
  const switches = getSwitches()

  if (!!switches) {
    try {
      return switches[key]
    } catch (e) {
      console.log(`Switch ${key} not found.`)
      return null
    }
  } else {
    return null
  }
}

export const isValidURL = url => {
  if (!!url) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ) // fragment locator
    return !!pattern.test(url)
  } else {
    return false
  }
}

export const getCurrentCountry = () => {
  if (isBrowser()) {
    return window.location.href.split("/")[3]
  }
  return false
}

export const getCurrentLanguage = () => {
  if (isBrowser()) {
    return window.location.href.split("/")[4]
  }
  return false
}

export const isCookieBucketingEnabled = (countryList) => {
  let currentCountry = getCurrentCountry().toUpperCase()
  return (
    countryList &&
    countryList === currentCountry
  )
}

export const getCookie = cname => {
  if (isBrowser()) {
    let name = `${cname}=`
    let ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1)
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const getSwitches = () => {
  try {
    var switchesStr = sessionStorage.getItem("switches")
    var validSwitchesStr = switchesStr.replace(/'/g, '"')
    return JSON.parse(validSwitchesStr)
  } catch (e) {
    console.log(`Switches not found.`)
    return null
  }
}

export const getUrlParameter  = name => {
  var urlParams = window.location.search || window.location.hash
  var returnValue = decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(urlParams) || [, ""])[1].replace(/\+/g, '%20')) || null

  if (returnValue !== undefined && returnValue !== null) {
    returnValue = returnValue.trim()
  }

  return returnValue
}
export const getQueryParameter  = () => {
  const urlParams = window.location.search || window.location.hash
  const fullUrl = window.location.href
  if(fullUrl.indexOf("currency-converter") > -1){
    return urlParams
  } 
  return ""
}
export const deleteCookie = name => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const getCountryCodeAndLanguage = () =>{
  let countryAndLanguageCode = {};
  const country = getCookie("WUCountryCookie_") || 'us';
  const language = getCookie("WULanguageCookie_") || 'en';
  countryAndLanguageCode.countryCode = country.toLowerCase();
  countryAndLanguageCode.langCode = language.toLowerCase();
  return countryAndLanguageCode;
}

export const getLoginUrl = () => {
  const codeObj = getCountryCodeAndLanguage();
  const getOrigin = window.location.origin;
  const countryCode = codeObj.countryCode;
  const langCode = codeObj.langCode;
  const loginUrl = `${getOrigin}/${countryCode}/${langCode}/web/user/login`;
  return loginUrl;
}

export const getLogoutUrl = () => {
  const codeObj = getCountryCodeAndLanguage();
  const getOrigin = window.location.origin;
  const countryCode = codeObj.countryCode;
  const langCode = codeObj.langCode;
  const logoutUrl = `${getOrigin}/${countryCode}/${langCode}/web/user/logout`;
  return logoutUrl;
}

export const removeFromStorage = () => {
  window.sessionStorage.removeItem('AccountNumber');
  window.sessionStorage.removeItem('GatewayCustomer');
  deleteCookie('SessionId');
  deleteCookie('SessionDomain');
  deleteCookie('SessionInfo');
}

export const replaceImageStyle = (
  content
) => {
  return content && content.replace(/<img style="max-width:100%;width:100px"/g, '<img ')
}

export const setHeaderFooterFromCookie = (headerContentCookie, footerContentCookie, isHeadLess) => {
  if (!isHeadLess){
    document.querySelector(".entry-content").innerHTML = headerContentCookie;
    document.querySelector("footer").innerHTML = footerContentCookie;
  }
}

export const setHeaderFooterOnCountryLang = (headerFooterContent , countryLang) => {
  return [
    headerFooterContent[`headerContent${countryLang}`] ? headerFooterContent[`headerContent${countryLang}`] : headerFooterContent[`headerContentUsEn`],
    headerFooterContent[`footerContent${countryLang}`] ? headerFooterContent[`footerContent${countryLang}`] : headerFooterContent[`footerContentUsEn`]
  ]
}

const setMediaAttribute = () => {
  const mediaPrint = document.querySelectorAll('[media="print"]')
  for (let i = 0; i < mediaPrint.length; i++) {
    mediaPrint[i].setAttribute('media', 'screen');
  }
}

export const setSmoIcon = (pageContext) => {
  pageContext.smoConfigJs && appendScript('script', undefined, 'smoConfig', pageContext.smoConfigJs)
  
  setMediaAttribute();
  if (document.documentMode) {
    appendScript('script', '/staticassets/scripts/main.js', 'mainJs');
  } else {
    appendScript('script', pageContext.jsData, 'jsData');
  }
  window.wuIconsJson = pageContext.wuIconsListComplete
  appendScript('script', `/staticassets/scripts/icon-loader.js`, 'icon-loader');
}