
const { isValidURL } = require('./utils')

exports.AdobeLaunchScript = (
  options
) => {
  try {
    if(
      !!options &&
      !!options.url
    ) {
      const envName = window.sessionStorage.getItem('environmentName'),
        url = JSON.parse(options.url)[envName.toLowerCase()]

      if(
        !isValidURL(url)
      ) {
        console.log(`Please verify the adobe launch url: ${url}`)
        return
      }

      (function (e, t) {
        e.analyticsObject = ''
      })(window, document)

      return { 
        src: url,
        defer: !!options.defer,
        async: !!options.async,
        beforeLoad: !!options.beforeLoad,
        onDCL: !!options.onDCL
      }
    } else {
      console.log(`Please verify the adobe launch url: ${options.url}`)
    }
  } catch (
    e
  ) {
    console.log(e)
    console.log(`There is an error. Adobe launch cannot be loaded.`)
  }
}
