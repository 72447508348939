const {
  isValidURL,
  getCurrentCountry,
  isBrowser,
  isHomePage,
} = require("./utils")

exports.BranchioScript = options => {
  try {
    if (!!options && !!options.url) {
      const url = options.url,
        apiKey = options.apiKey,
        isApplicable = !!(
          options.countryList.indexOf(getCurrentCountry().toUpperCase()) > -1 &&
          isHomePage()
        )

      if (isValidURL(url) && !!isApplicable && !!apiKey) {
        return {
          src: url,
          defer: !!options.defer,
          beforeLoad: !!options.beforeLoad,
          async: !!options.async,
          onDCL: !!options.onDCL,
          data: {
            "api-key": apiKey,
          },
          onload: function() {
            if (isBrowser())
              window.branch.init(this.getAttribute("data-api-key"))
          },
        }
      } else {
        return
      }
    } else {
      console.log(`Please verify the evergage url: ${options.url}`)
    }
  } catch (e) {
    console.log(`There is an error. Evergage cannot be loaded.`)
  }
}
