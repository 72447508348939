const appendScript = (type, src, key, JsData) => {
    const oScript = document.createElement(type)
    if(src) {
        oScript.setAttribute('src', src)
        oScript.setAttribute('data-id', key);
    } else {
        const oScriptText = document.createTextNode(JsData)
        oScript.appendChild(oScriptText)
    }
    document.body.appendChild(oScript);
}

export default appendScript;