let getSwitches = () => {
  try {
    var switchesStr = sessionStorage.getItem("switches")
    var validSwitchesStr = switchesStr.replace(/'/g, '"')
    return JSON.parse(validSwitchesStr)
  } catch (e) {
    console.log(`Switches not found.`)
    return null
  }
}

let getOtScriptDomains = () => {
  try {
    const otScriptDomainStr = sessionStorage.getItem("otscriptpath")
    var validOtScriptDomainStr = otScriptDomainStr.replace(/'/g, '"')
    return JSON.parse(validOtScriptDomainStr)
  } catch (e) {
    console.log(`otscript domain not found.`)
    return null
  }
}

let getOtScriptDomain = key => {
  const otScriptDomain = getOtScriptDomains()

  if (!!otScriptDomain) {
    try {
      return otScriptDomain[key]
    } catch (e) {
      console.log(`otScript ${key} not found.`)
      return null
    }
  } else {
    return null
  }
}

let getSwitch = key => {
  const switches = getSwitches()

  if (!!switches) {
    try {
      return switches[key]
    } catch (e) {
      console.log(`Switch ${key} not found.`)
      return null
    }
  } else {
    return null
  }
}

let isValidURL = url => {
  if (!!url) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ) // fragment locator
    return !!pattern.test(url)
  } else {
    return false
  }
}

let loadScript = script => {
  (function(e, t) {
    var r = t.createElement("script")
    r.type = "text/javascript"
    r.charset = "UTF-8"

    for (let attr in script) {
      const val = script[attr]

      if (typeof val === "boolean") {
        if (val) r.setAttribute(attr, val)
      } else if (typeof val === "string") {
        r.setAttribute(attr, val)
      } else if (typeof val === "function") {
        r.setAttribute(attr, val)
      } else if (typeof val === "object") {
        for (let key in val) {
          const dataKey = `data-${key}`,
            dataVal = val[key]

          if (typeof dataVal === "boolean") {
            if (dataVal) r.setAttribute(dataKey, dataVal)
          } else if (typeof dataVal === "string") {
            r.setAttribute(dataKey, dataVal)
          } else if (typeof dataVal === "function") {
            r.setAttribute(dataKey, dataVal)
          }
        }
      }
    }

    t.getElementsByTagName(script.inHead ? "head" : "body")[0].appendChild(r)
    if (script.onload && typeof script.onload === "function") {
      r.onload = script.onload
    }
  })(window, document)
}

let loadScripts = scripts => {
  for (let index in scripts) {
    const script = scripts[index]
    loadScript(script)
  }
}

const getCookie = cname => {
  if (isBrowser()) {
    let name = `${cname}=`
    let ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1)
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
    }
  }
  return ""
}

const setCookie = (cname, cvalue, exdays) => {
  let d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = "expires=" + d.toUTCString()
  let cookie = cname + "=" + cvalue + ";" + expires + ";path=/;"
  if (window.location.protocol.indexOf("https") !== -1) {
    cookie += "secure;"
  }
  document.cookie = cookie
}

const isCategoryEnabled = (category, isGDPR, userCookieValue) => {
  if(!userCookieValue && isGDPR){
    userCookieValue = ",C0001,"
  }
  if (!category || !userCookieValue) return true
  return userCookieValue.indexOf(category) > -1
}

const isBrowser = () => typeof window !== "undefined"

const pageName=['home','currency-converter'];
const isHomePage = () => {
  if (isBrowser()) {
    return pageName.some(page => ~window.location.href.indexOf(page))
  }
  return false
}

const getEnv = () => {
  let env
  if (isBrowser()) {
    const { protocol, hostname, port } = window.location
    if (hostname.includes("localhost")) {
      env = "https://wudispatcher-qa11.qawesternunion.com"
    } else {
      if (!window.location.origin) {
        // Internet Explorer does not have access to window.location.origin
        window.location.origin = `${protocol}//${hostname}${
          port ? `:${port}` : ""
        }`
      }
      env = window.location.origin
    }
    return env
  }
  return false
}


let isGDPRCountry = (countryList) => {
  let currentCountry = getCurrentCountry().toUpperCase()
  return countryList.indexOf(currentCountry) > -1
}

let getCurrentCountry = () => {
  if (isBrowser()) {
    return window.location.href.split("/")[3]
  }
  return false
}

let isCookieBucketingEnabled = (countryList) => {
  let currentCountry = getCurrentCountry().toUpperCase()
  return (
    countryList &&
    countryList === currentCountry
  )
}

let getAssetVersion = () => {
  if (isBrowser()) {
    const activeVersion = window.sessionStorage.getItem("activeVersion")
    if (activeVersion) {
      return JSON.parse(activeVersion).assetVersion
    }
  }
  return
}

let getCurrentLanguage = () => {
  return isBrowser() ? window.location.href.split("/")[4] : false
}

exports.getSwitches = getSwitches
exports.getSwitch = getSwitch
exports.isValidURL = isValidURL
exports.loadScript = loadScript
exports.loadScripts = loadScripts
exports.getCookie = getCookie
exports.setCookie = setCookie
exports.isCategoryEnabled = isCategoryEnabled
exports.isBrowser = isBrowser
exports.isHomePage = isHomePage
exports.getEnv = getEnv
exports.getCurrentCountry = getCurrentCountry
exports.isCookieBucketingEnabled = isCookieBucketingEnabled
exports.getAssetVersion = getAssetVersion
exports.getCurrentLanguage = getCurrentLanguage
exports.getOtScriptDomain = getOtScriptDomain
exports.isGDPRCountry = isGDPRCountry
