const hostname = window.location.hostname.toUpperCase()

const env =
  hostname.indexOf('LOCALHOST') > -1 ? 'LOCALHOST' :
  hostname.indexOf('QA') > -1 ? 'QA' :
  hostname.indexOf('DEV') > -1 ? 'DEV' :
  hostname.indexOf('UAT') > -1 ? 'UAT' :
  'PROD',
  preHostname = hostname.split('.')[0],
  envName = (
    (preHostname.toLowerCase() === 'www' || preHostname.toLowerCase() === 'partners') ? 'PROD' :
    hostname.split('.')[0].split('-')[1] === undefined ? 'LOCALHOST' :
    hostname.split('.')[0].split('-')[1]
  )

window.sessionStorage.setItem('environment', env)
window.sessionStorage.setItem('environmentName', envName)
window.sessionStorage.setItem('isProd', env === 'PROD')

const getUrlParameter  = name => {
  var urlParams = window.location.search || window.location.hash
  var returnValue = decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(urlParams) || [, ""])[1].replace(/\+/g, '%20')) || null

  if (returnValue !== undefined && returnValue !== null) {
    returnValue = returnValue.trim()
  }

  return returnValue
}

window.getUrlParameter = getUrlParameter 

exports.onClientEntry = () => {
  require('./src/services/lazyload-images')  
  const utm_data = { 'AFFILIATES': 'afc_1,aff,aff_,aff-aw,aff-fri,afyf,americreditfinance,aupost,cosk.net,ffiliation,guardian,net,netaf,netaffiliation,tradetracker,us_aff,us_cj0311,us_cpa,wucanadaps,http://startsear.ch/?aff', 'DISPLAY': 'dfa,displ,display,display_,dlay,nexcom,nigeriaso8_,splay', 'EMAIL': 'abandon0transactions,abandon1transactions12mon,emai,email,email_,eml,fbopsweeps2012,serviceeu110908,usereg0409', 'INTERNAL': 'au_en_locator,es_ch_hp_promoad1,hp_hero,hp_promad,other_agentlocator_d2bq312,other_agentlocator_onlineq312,other_agentlocator_q312,other_priceit_d2bq312,other_priceit_onlineq312,other_transferstatus_d2bq312,other_transferstatus_onlineq312,otherdebid0911,us_en_hp_hero_banner,us_en_hp_promoad,wucanada,wucanadaal,wucanadacorp,wucanadaap,wucanadaaps,wucanadasend,wucanadaaxs,wugabonps', 'MOBILE': 'mobile,mobile_', 'OTHER': '917286_62519727_239743728,917286_62519727_239743728,917286_67297887_240960096,917286_74246682_248943900,917286_74294882_248723639,americreditfinance6 -,dootv_2dootv,mx_bts_0811,pounds sterling to sek_paneuro_se_q112,ramadanenglishus western union promotion code', 'RETAIL': 'ret,ret_,retail', 'SEARCHENGINES': 'google,yandex,yahoo,msn,bing,creativecommons,duckduckgo,swisscows,startpage,gibiru,wiki,onesearch,ask,baidu,naver,aol,excite,lycos,chacha, wolframalpha,givewater,ekoru,ecosia', 'SOCIALSITES': 'facebook,instagram,twitter,reddit,Pinterest' }
  sessionStorage.setItem('UTMSourcesList', JSON.stringify(utm_data))
  var allAnchors = document.getElementsByTagName('a');
  [].forEach.call(allAnchors, function(anchor) {
    anchor.getAttribute('target') !== '_blank' && anchor.addEventListener('click', function(e, t, s) {
      e.preventDefault();
      e.stopPropagation();
      if(window.amplitude && window.logClickEvent){
        logClickEvent(e, t, s)
      }
      window.location=this.getAttribute('href');
  })});
}

exports.onPreRouteUpdate = ({ location: { pathname } }) => {
  window.amplitude = false;
  if (!sessionStorage.getItem(`@@scroll|${location.pathname}|initial`)) {
    window.scrollTo(0, 0);
  }
  if ((/\/currency-converter\/.+\.html$/.test(pathname)) || location.hash.startsWith("#")) { // Fix for scroll issue in cc pages 😇 
    window.history.scrollRestoration = 'manual'
    for (const item in sessionStorage) {
      /^@@scroll/.test(item) && sessionStorage.removeItem(item)
    }
  } else window.history.scrollRestoration = 'auto'
}

exports.onRouteUpdate = ({ location , prevLocation }) => {
  if (prevLocation && !location.hash.startsWith("#")) {
    window.location.reload();
  }
}
