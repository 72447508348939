
import { isBrowser } from '../utils/common.js'

!!isBrowser() && (function (w, d) {
  console.log('Lazyload Images')

  var deferLoadingCalled = false
  var scrollListenerRemoved = false
  var mobileSrc = 'data-lazyload-mobile-src', 
      mobileClass = 'data-lazyload-mobile-class', 
      desktopSrc = 'data-lazyload-desktop-src', 
      desktopClass = 'data-lazyload-desktop-class', 
      mobileDesktopSrc = 'data-lazyload-mobile-desktop-src', 
      mobileDesktopClass = 'data-lazyload-mobile-desktop-class'

    var deferImgObj = {
    mobile: {
      show: false,
      image: [].slice.call(d.querySelectorAll('[' + mobileSrc + ']')),
      bgImage: [].slice.call(d.querySelectorAll('[' + mobileClass + ']'))
    },
    desktop: {
      show: false,
      image: [].slice.call(d.querySelectorAll('[' + desktopSrc + ']')),
      bgImage: [].slice.call(d.querySelectorAll('[' + desktopClass + ']'))
    },
    mobileDesktop: {
      show: false,
      image: [].slice.call(d.querySelectorAll('[' + mobileDesktopSrc + ']')),
      bgImage: [].slice.call(d.querySelectorAll('[' + mobileDesktopClass + ']'))
    }
  }

  function swapSrcAttributes(device, attr) {
    var i = 0
    while (i < deferImgObj[device]['image'].length) {
      var ele = deferImgObj[device]['image'][i]
      if (getComputedStyle(ele).display === 'none') ele.style.display = 'inline'
      if (ele.getAttribute('src') !== ele.getAttribute(attr) &&
        (ele.getBoundingClientRect().top <= w.innerHeight && ele.getBoundingClientRect().bottom > 0)) {
        ele.setAttribute('src', ele.getAttribute(attr))
        deferImgObj[device]['image'].splice(i, 1)
      } else i++
    }
  }

  function addLazyLoadClass(device, cssClass) {
    var i = 0
    while (i < deferImgObj[device]['bgImage'].length) {
      var ele = deferImgObj[device]['bgImage'][i]
      if ((ele.getBoundingClientRect().top <= w.innerHeight && ele.getBoundingClientRect().bottom > 0)) {
        ele.className += ' ' + ele.getAttribute(cssClass)
        deferImgObj[device]['bgImage'].splice(i, 1)
      } else i++
    }
  }

  function processDeferLoading() {
    if (!deferLoadingCalled) {
      deferLoadingCalled = true
      deferImgObj.mobile.image =  [].slice.call(d.querySelectorAll('[' + mobileSrc + ']'))
      deferImgObj.mobile.bgImage = [].slice.call(d.querySelectorAll('[' + mobileClass + ']'))
      deferImgObj.desktop.image =  [].slice.call(d.querySelectorAll('[' + desktopSrc + ']'))
      deferImgObj.desktop.bgImage = [].slice.call(d.querySelectorAll('[' + desktopClass + ']'))
      deferImgObj.mobileDesktop.image = [].slice.call(d.querySelectorAll('[' + mobileDesktopSrc + ']'))
      deferImgObj.mobileDesktop.bgImage = [].slice.call(d.querySelectorAll('[' + mobileDesktopClass + ']'))
      setTimeout(function () {
        
        if (d.documentElement.clientWidth <= 767) {
          if (!deferImgObj.mobile.show) {
            swapSrcAttributes('mobile', mobileSrc)
            addLazyLoadClass('mobile', mobileClass)
            if (deferImgObj.mobile.image.length === 0 && deferImgObj.mobile.bgImage.length === 0) deferImgObj.mobile.show = true
          }
        } else {
          if (!deferImgObj.desktop.show) {
            swapSrcAttributes('desktop', desktopSrc)
            addLazyLoadClass('desktop', desktopClass)
            if (deferImgObj.desktop.image.length === 0 && deferImgObj.desktop.bgImage.length === 0) deferImgObj.desktop.show = true
          }
        }
        if (!deferImgObj.mobileDesktop.show) {
          swapSrcAttributes('mobileDesktop', mobileDesktopSrc)
          addLazyLoadClass('mobileDesktop', mobileDesktopClass)
          if (deferImgObj.mobileDesktop.image.length === 0 && deferImgObj.mobileDesktop.bgImage.length === 0) deferImgObj.mobileDesktop.show = true
        }

        var imgLoadedForCurrentDevice = ((d.documentElement.clientWidth <= 767) && deferImgObj.mobile.show)
          || ((d.documentElement.clientWidth > 767) && deferImgObj.desktop.show)
        if (imgLoadedForCurrentDevice && deferImgObj.mobileDesktop.show) {
          w.removeEventListener("scroll", processDeferLoading)
          scrollListenerRemoved = true
        }
        if (!imgLoadedForCurrentDevice && scrollListenerRemoved) {
          w.addEventListener("scroll", processDeferLoading)
          scrollListenerRemoved = false
        }
        if (deferImgObj.mobile.show && deferImgObj.desktop.show && deferImgObj.mobileDesktop.show) w.removeEventListener("resize", processDeferLoading)
        deferLoadingCalled = false
      }, 200)
    }
  }

  w.addEventListener("scroll", processDeferLoading)
  w.addEventListener("resize", processDeferLoading)
  w.addEventListener("load", processDeferLoading)
})(window, document)
